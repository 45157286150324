import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Box } from '@core';
import { Text } from '@components';
import { ImageCore } from '@components/image';

import loaderInner from '@images/loader/loader-inner.svg';
import loaderOuter from '@images/loader/loader-outer.svg';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const fadeInOut = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const AnimatedItem = styled(ImageCore)`
  animation-name: ${rotate360};
  animation-duration: 1200ms;
  animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
  animation-iteration-count: infinite;
`;

const AnimatedSpan = styled.span`
  font-size: inherit;
  font-weight: inherit;
  color: inherit;

  animation-name: ${fadeInOut};
  animation-duration: 1200ms;
  animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
  animation-iteration-count: infinite;
  animation-delay: ${({ animationDelay }) => animationDelay && `${animationDelay}ms`};
`;

const LoaderWithText = ({ isLoading }) => {
  const { t } = useTranslation();

  return isLoading ? (
    <Box
      width={104}
      display="flex"
      flexDirection="column"
      alignItems="center"
      borderRadius="md"
      py={24}
    >
      <Box position="relative" width={56} height={56} mb={16}>
        <ImageCore src={loaderOuter} alt="Outer loader" position="absolute" top={0} left={0} />
        <AnimatedItem src={loaderInner} alt="Inner loader" position="absolute" top={0} left={0} />
      </Box>
      <Text.Body4 fontSize={10} lineHeight="16px">
        {t('common:loading')}
        <AnimatedSpan animationDelay={-400}>.</AnimatedSpan>
        <AnimatedSpan animationDelay={-300}>.</AnimatedSpan>
        <AnimatedSpan animationDelay={-150}>.</AnimatedSpan>
      </Text.Body4>
    </Box>
  ) : null;
};

export default LoaderWithText;
